import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import "./Footer.css";
import FooterItem from "./FooterItem";

function Footer(props) {
  const { t } = useTranslation();
  const companyTitle = props.title;

  return (
    <>
      <div className="web-footer">
        <div className="footer">
          <Container>
            <div className="footer-top">
              <div className="widget">
                <div className="widget-title">{companyTitle}</div>
                <div className="widget-body">
                  <ul>
                    <li>
                      <a href="/page/about">{t("about_us")}</a>
                    </li>
                    <li>
                      <a href="/page/contact">{t("contact")}</a>
                    </li>
                    <li>
                      <a href="/page/impressum">{t("legal_notice")}</a>
                    </li>
                    <li>
                      <a href="/page/agb">{t("conditions")}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="widget">
                <div className="widget-title">{t("mobile_app")}</div>
                <div className="widget-body">
                  <div className="mobile-icon">
                    <a
                      href={props.storeURLs.iosURL}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        marginLeft: "0.5%",
                        marginRight: "0.5%",
                      }}
                    >
                      <img src="../theme-1/ios.png" alt="Google Play" />
                    </a>
                  </div>
                  <div className="mobile-icon">
                    <a
                      href={props.storeURLs.androidURL}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        marginLeft: "0.5%",
                        marginRight: "0.5%",
                      }}
                    >
                      <img src="../theme-1/android.png" alt="Google Play" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="footer-bottom">
          <p>
            <a href="https://b2go.systems/">www.b2go.systems</a>
          </p>
        </div>
      </div>
      <div className="m-footer">
        <div className="mobile-widget">
          <div className="widget-title">{t("mobile_app")}</div>
          <div className="widget-body">
            <div className="mobile-icon">
              <a
                href={props.storeURLs.iosURL}
                target="_blank"
                rel="noreferrer"
                style={{
                  marginLeft: "0.5%",
                  marginRight: "0.5%",
                }}
              >
                <img src="/theme-1/ios.png" alt="Google Play" />
              </a>
            </div>
            <div className="mobile-icon">
              <a
                href={props.storeURLs.androidURL}
                target="_blank"
                rel="noreferrer"
                style={{
                  marginLeft: "0.5%",
                  marginRight: "0.5%",
                }}
              >
                <img src="/theme-1/android.png" alt="Google Play" />
              </a>
            </div>
          </div>
        </div>
        <div className="accordion-widgets">
          <FooterItem title={companyTitle} />
        </div>
      </div>
    </>
  );
}

export default Footer;
