import { ShoppingBasket } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Grid,
  InputBase,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BasketProductItem from "../../components/BasketProductItem/BasketProductItem";
import { orderService } from "../../services/orderService";
import BasketContext from "../../store/basket-context";
import LoginContext from "../../store/login-context";
import useFormatHelper from "../../useFormatHelper";
import ProductItem from "../../components/ProductItem/ProductItem";
import "./Basket.css";

import { tns } from "tiny-slider/src/tiny-slider";

function Basket(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { formatMoney } = useFormatHelper();

  const { loginContext } = useContext(LoginContext);
  const { basketContext } = useContext(BasketContext);
  const [note, setNote] = useState("");
  const [noteDisplay, setNoteDisplay] = useState(false);

  var products = [
    {
      id: 1383,
      name: "Pepsi 0,33l, 24er Pack",
      unit: "24",
      image: "/files/3afb70f0-ec24-4147-b231-1a201894ce2f.png",
      favorite: true,
      brandId: 2381,
      tax: {
        name: "MwSt 20%",
        value: 20,
      },
      categoryId: 17,
      subCategoryId: 1489,
      salesUnit: {
        id: 1386,
        unitName: "Box",
        piece: 24,
        price: 7,
        unitPrice: 0.2916666666666667,
        artikelNumber: "102",
      },
      discount: {
        piece: 48,
        price: 0.25,
        startDate: "2022-10-16T00:00:00.000+00:00",
        endDate: "2024-05-01T00:00:00.000+00:00",
      },
    },
    {
      id: 1836,
      name: "Pago Erdbeer",
      unit: "200ml",
      image: "/images/empty.png",
      favorite: false,
      brandId: 652,
      tax: {
        name: "MwSt 20%",
        value: 20,
      },
      categoryId: 17,
      subCategoryId: 1493,
      salesUnit: {
        id: 1838,
        unitName: "Piece",
        piece: 1,
        price: 0.8,
        unitPrice: 0.8,
        artikelNumber: "5002",
      },
      discount: {
        piece: 1,
        price: 0.78,
        startDate: "2022-11-28T00:00:00.000+00:00",
        endDate: "2024-11-29T00:00:00.000+00:00",
      },
    },
    {
      id: 1503,
      name: "Fanta 0,5l, 24 STK",
      unit: "24",
      image: "/files/eb1bd2af-d7f5-40b9-ba44-cd90e2d6ded8.png",
      favorite: false,
      brandId: 652,
      tax: {
        name: "MwSt 20%",
        value: 20,
      },
      categoryId: 17,
      subCategoryId: 1491,
      salesUnit: {
        id: 1505,
        unitName: "Box",
        piece: 24,
        price: 15.5,
        unitPrice: 0.6458333333333334,
        artikelNumber: "345345",
      },
      discount: {
        piece: 1,
        price: 0.22,
        startDate: "2023-10-01T00:00:00.000+00:00",
        endDate: "2023-12-31T00:00:00.000+00:00",
      },
    },
    {
      id: 1561,
      name: "Carott 25 Kg",
      unit: "25",
      image: "/files/71562eb3-841f-4316-b998-f7c6c3d315b1.jpg",
      favorite: false,
      brandId: 654,
      tax: {
        name: "MwSt 10%",
        value: 10,
      },
      categoryId: 121,
      salesUnit: {
        id: 1563,
        unitName: "Box",
        piece: 25,
        price: 14,
        unitPrice: 0.56,
        artikelNumber: "106",
      },
    },
    {
      id: 1535,
      name: "Citrone 10 Kg",
      unit: "10",
      image: "/files/bc198ae3-435e-4594-a3ec-c1e1f7f7f0c6.jpg",
      favorite: false,
      brandId: 654,
      tax: {
        name: "MwSt 20%",
        value: 20,
      },
      categoryId: 121,
      salesUnit: {
        id: 1537,
        unitName: "Box",
        piece: 10,
        price: 20,
        unitPrice: 2,
        artikelNumber: "345345",
      },
    },
    {
      id: 1365,
      name: "Coca Cola 0,33l, 24er Pack",
      unit: "24",
      image: "/files/24eb92db-6e74-4f85-93be-755aa295dc36.png",
      favorite: true,
      brandId: 2382,
      tax: {
        name: "MwSt 20%",
        value: 20,
      },
      categoryId: 17,
      subCategoryId: 1489,
      salesUnit: {
        id: 1367,
        unitName: "Box",
        piece: 24,
        price: 11,
        unitPrice: 0.4583333333333333,
        artikelNumber: "301",
      },
    },
    {
      id: 72,
      name: "Danone Actimel Granatapfel 6x100ml",
      unit: "100ml",
      image: "/files/4344c1c0-89a1-4114-a332-f028127aaa7a.png",
      favorite: false,
      brandId: 652,
      tax: {
        name: "MwSt 10%",
        value: 10,
      },
      categoryId: 1353,
      salesUnit: {
        id: 74,
        unitName: "Piece",
        piece: 1,
        price: 20,
        unitPrice: 20,
        artikelNumber: "103",
      },
    },
    {
      id: 2693,
      name: "Ganze Seite 1/1",
      unit: "1",
      image: "/images/empty.png",
      favorite: false,
      brandId: 2382,
      tax: {
        name: "MwSt 20%",
        value: 20,
      },
      categoryId: 117,
      salesUnit: {
        id: 2695,
        unitName: "Piece",
        piece: 1,
        price: 0,
        unitPrice: 0,
        artikelNumber: "1",
      },
    },
    {
      id: 1548,
      name: "Kapia 10 Kg",
      unit: "10",
      image: "/files/0630e218-85ab-41ae-a820-6143e3ae4f56.jpg",
      favorite: false,
      brandId: 654,
      tax: {
        name: "MwSt 10%",
        value: 10,
      },
      categoryId: 121,
      salesUnit: {
        id: 1550,
        unitName: "Box",
        piece: 10,
        price: 28,
        unitPrice: 2.8,
        artikelNumber: "5675",
      },
    },
    {
      id: 1470,
      name: "Mis Ayran 250ml, 24er Pack",
      unit: "24",
      image: "/files/c3b3159f-8a80-443e-aa89-a31f8cc9709e.png",
      favorite: false,
      brandId: 651,
      tax: {
        name: "MwSt 10%",
        value: 10,
      },
      categoryId: 17,
      subCategoryId: 1493,
      salesUnit: {
        id: 1472,
        unitName: "Box",
        piece: 24,
        price: 12,
        unitPrice: 0.5,
        artikelNumber: "105",
      },
    },
    {
      id: 1400,
      name: "Nazar Käsekrainer 500g",
      unit: "1",
      image: "/files/a539340f-6997-4857-9b16-d933b76df9a3.png",
      favorite: false,
      brandId: 651,
      tax: {
        name: "MwSt 10%",
        value: 10,
      },
      categoryId: 119,
      salesUnit: {
        id: 1402,
        unitName: "Piece",
        piece: 1,
        price: 3.6,
        unitPrice: 3.6,
        artikelNumber: "345345",
      },
    },
    {
      id: 1413,
      name: "Nazar Picknick-Jausenwurst, 500g",
      unit: "1",
      image: "/files/ef989a02-8823-4ec6-b46f-b9842ac6c00a.png",
      favorite: false,
      brandId: 651,
      tax: {
        name: "MwSt 10%",
        value: 10,
      },
      categoryId: 119,
      salesUnit: {
        id: 1415,
        unitName: "Piece",
        piece: 1,
        price: 3.25,
        unitPrice: 3.25,
        artikelNumber: "345345",
      },
    },
    {
      id: 1526,
      name: "Oranges 8 KG",
      unit: "1",
      image: "/files/6d0d0b44-4e9e-4f38-bc68-5fc7146af021.jpg",
      favorite: false,
      brandId: 12,
      tax: {
        name: "MwSt 10%",
        value: 10,
      },
      categoryId: 121,
      salesUnit: {
        id: 1528,
        unitName: "Box",
        piece: 8,
        price: 9,
        unitPrice: 1.125,
        artikelNumber: "104",
      },
    },
    {
      id: 1828,
      name: "Pago",
      unit: "200ml",
      image: "/images/empty.png",
      favorite: false,
      brandId: 652,
      tax: {
        name: "MwSt 20%",
        value: 20,
      },
      categoryId: 17,
      subCategoryId: 1493,
      salesUnit: {
        id: 1830,
        unitName: "Piece",
        piece: 1,
        price: 1.09,
        unitPrice: 1.09,
        artikelNumber: "5001",
      },
    },
    {
      id: 1495,
      name: "Pepsi Cola 0,5l, 12 Piece",
      unit: "12",
      image: "/files/a1936991-074e-40ee-8f2c-550f888420f7.png",
      favorite: false,
      brandId: 2381,
      tax: {
        name: "MwSt 20%",
        value: 20,
      },
      categoryId: 17,
      subCategoryId: 1489,
      salesUnit: {
        id: 1497,
        unitName: "Box",
        piece: 12,
        price: 0,
        unitPrice: 0,
        artikelNumber: "345345",
        numberOfItemInBasket: 1,
      },
    },
    {
      id: 1426,
      name: "Pizzakarton 50x50cm, 50 STK",
      unit: "50",
      image: "/files/29931516-ef80-4acf-bff3-8f569e7f7035.jpeg",
      favorite: false,
      brandId: 651,
      tax: {
        name: "MwSt 20%",
        value: 20,
      },
      categoryId: 123,
      salesUnit: {
        id: 1428,
        unitName: "Box",
        piece: 50,
        price: 28,
        unitPrice: 0.56,
        artikelNumber: "987",
      },
    },
    {
      id: 1777,
      name: "Pueble Grün",
      unit: "1",
      image: "/files/6aecac0c-f9af-4000-80a1-8cb1dac9eb92.jpeg",
      favorite: false,
      brandId: 653,
      tax: {
        name: "MwSt 20%",
        value: 20,
      },
      categoryId: 123,
      salesUnit: {
        id: 1779,
        unitName: "Piece",
        piece: 1,
        price: 6.99,
        unitPrice: 6.99,
        artikelNumber: "42302025",
      },
    },
    {
      id: 1732,
      name: "Römerzeit 0,5l",
      unit: "1",
      image: "/files/f66a544a-c933-4c41-8628-2f20bbb0b5a9.jpeg",
      favorite: false,
      brandId: 652,
      tax: {
        name: "MwSt 20%",
        value: 20,
      },
      categoryId: 17,
      subCategoryId: 1491,
      salesUnit: {
        id: 1734,
        unitName: "Box",
        piece: 12,
        price: 0,
        unitPrice: 0,
        artikelNumber: "90311659",
      },
    },
    {
      id: 1392,
      name: "Servietten, 1-lagig, 33x33cm, 8 Packungen",
      unit: "8",
      image: "/files/2b7c48a9-f59c-41c0-b8f7-29df8dbbdd8a.jpeg",
      favorite: false,
      brandId: 652,
      tax: {
        name: "MwSt 20%",
        value: 20,
      },
      categoryId: 123,
      salesUnit: {
        id: 1394,
        unitName: "Box",
        piece: 8,
        price: 0,
        unitPrice: 0,
        artikelNumber: "354",
      },
    },
    {
      id: 47,
      name: "Sprite 6x0,33ml",
      unit: "250ml",
      image: "/files/9a984400-28f6-452b-8184-9454b7999b7a.jpeg",
      favorite: false,
      brandId: 2382,
      tax: {
        name: "MwSt 10%",
        value: 10,
      },
      categoryId: 17,
      subCategoryId: 1489,
      salesUnit: {
        id: 49,
        unitName: "Piece",
        piece: 1,
        price: 21,
        unitPrice: 21,
        artikelNumber: "101",
        numberOfItemInBasket: 1,
      },
    },
    {
      id: 1449,
      name: "Strauch Tomaten",
      unit: "1",
      image: "/files/61702b44-ca76-470c-b697-02ced9b82dab.jpeg",
      favorite: false,
      brandId: 651,
      tax: {
        name: "MwSt 10%",
        value: 10,
      },
      categoryId: 121,
      salesUnit: {
        id: 1451,
        unitName: "Palett",
        piece: 500,
        price: 2500,
        unitPrice: 5,
        artikelNumber: "234234",
      },
    },
    {
      id: 1900,
      name: "Sultan Meat",
      unit: "24",
      image: "/images/empty.png",
      favorite: false,
      brandId: 1898,
      tax: {
        name: "MwSt 10%",
        value: 10,
      },
      categoryId: 17,
      subCategoryId: 1489,
      salesUnit: {
        id: 1902,
        unitName: "Box",
        piece: 24,
        price: 0,
        unitPrice: 0,
        artikelNumber: "234234",
      },
    },
    {
      id: 2746,
      name: "test-excel-1",
      unit: "25 g",
      image: "/images/empty.png",
      favorite: false,
      brandId: 654,
      tax: {
        name: "MwSt 10%",
        value: 10,
      },
      categoryId: 117,
      salesUnit: {
        id: 2748,
        unitName: "Piece",
        piece: 1,
        price: 16.99,
        unitPrice: 16.99,
        artikelNumber: "19950.0",
      },
    },
    {
      id: 2745,
      name: "Test Produkt",
      unit: "1",
      image: "/images/empty.png",
      favorite: false,
      brandId: 653,
      tax: {
        name: "MwSt 10%",
        value: 10,
      },
      categoryId: 117,
      salesUnit: {
        id: 2747,
        unitName: "Piece",
        piece: 1,
        price: 2.5,
        unitPrice: 2.5,
        artikelNumber: "111111",
      },
    },
    {
      id: 1441,
      name: "Tomato, 5 KG",
      unit: "1",
      image: "/files/808e9507-4374-4a4a-aa17-113eb829a74c.png",
      favorite: false,
      brandId: 653,
      tax: {
        name: "MwSt 10%",
        value: 10,
      },
      categoryId: 1353,
      salesUnit: {
        id: 1443,
        unitName: "Box",
        piece: 1,
        price: 0,
        unitPrice: 0,
        artikelNumber: "345345",
      },
    },
    {
      id: 2705,
      name: "Werbeabgabe 5%",
      unit: "1",
      image: "/images/empty.png",
      favorite: false,
      brandId: 653,
      tax: {
        name: "MwSt 20%",
        value: 20,
      },
      categoryId: 117,
      salesUnit: {
        id: 2707,
        unitName: "Piece",
        piece: 1,
        price: 0,
        unitPrice: 0,
        artikelNumber: "11111",
      },
    },
  ];

  useEffect(() => {
    basketContext.getItemsByService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketContext.counter]);

  // useEffect(() => {
  //   if (products && products.length > 0) {
  //     setTimeout(() => {
  //       tns({
  //         container: ".my-slider",
  //         autoplay: false,
  //         controls: true,
  //         nav: false,
  //         navPosition: "bottom",
  //         autoplayButtonOutput: false,
  //         controlsText: [
  //           "<i class='ri-arrow-left-line'></i>",
  //           "<i class='ri-arrow-right-line'></i>",
  //         ],
  //         items: 6,
  //       });
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   // if (location.pathname === "/payment" && !loginContext.user) {
  //   //   navigate("/");
  //   // }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loginContext.user]);

  function backHome() {
    navigate("/");
  }

  function passPayment() {
    if (!loginContext.user) {
      loginContext.setLoginState("open", true);
    } else if (
      basketContext != null &&
      basketContext.items != null &&
      basketContext.items.length > 0
    ) {
      basketContext.getOrderConfirmationData();
      approveBasket();
      //navigate("/payment");
    }
  }
  function backBasket() {
    basketContext.getItemsByService();
    navigate("/basket");
  }
  function approveBasket() {
    orderService
      .approveBasket(note)
      .then((response) => {
        enqueueSnackbar(t("order_save_successfully"), {
          variant: "success",
          autoHideDuration: 2000,
        });
        //basketContext.getItemsByService();
        navigate("/");
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  }
  return (
    <>
      <Container>
        <Box sx={{ mb: 4, mt: 3 }}>
          <div className="shopping-card-title">{t("shopping_card")}</div>
          <Grid
            container
            columns={{ xs: 1, md: 6 }}
            spacing={3}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Grid item xs={1} md={4}>
              <Box>
                <div className="basket-page sidebar-basket shopping-page big-basket">
                  {basketContext !== null &&
                    basketContext.items !== null &&
                    basketContext.items.length > 0 &&
                    basketContext.items.map((basketItem, index) => {
                      return (
                        <BasketProductItem
                          limit={60}
                          key={`basket_item_${basketItem.productId}_${basketItem.saleUnitId}_${index}`}
                          basketItem={basketItem}
                          readonly={props.readonly}
                          first={index === 0}
                          last={basketContext.items.length === index + 1}
                          home={true}
                        />
                      );
                    })}
                </div>
                {(basketContext === null ||
                  basketContext.items === null ||
                  basketContext.items.length === 0) && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box>
                      <ShoppingBasket
                        color="primary"
                        sx={{ fontSize: "250px", opacity: "0.5" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h6" component="div" color="primary">
                        {t("my_basket_empty")}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        color="text.secondary"
                      >
                        {t("my_basket_empty_2")}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={1} md={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  mb: 3,
                }}
              >
                {props.showDetail && (
                  <>
                    <Button
                      className="order-note-btn"
                      variant="container"
                      fullWidth
                      sx={{
                        textTransform: "none",
                        pt: "14px",
                        pb: "14px",
                        borderRadius: "8px",
                        mb: 2,
                        color: "#fff",
                      }}
                      onClick={() => setNoteDisplay(!noteDisplay)}
                    >
                      {t("note")}
                    </Button>
                    {noteDisplay && (
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "8px",
                          padding: "24px",
                          boxShadow: "rgb(93 62 188 / 4%) 0px 6px 24px",
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                          mb: 3,
                        }}
                      >
                        <InputBase
                          placeholder={t("enter_order_note")}
                          multiline
                          value={note}
                          onChange={(event) => {
                            setNote(event.target.value);
                          }}
                        />
                      </Box>
                    )}
                  </>
                )}
                <div className="basket-page-total total">
                  {basketContext.taxAmounts != null &&
                    basketContext.taxAmounts.length > 0 && (
                      <>
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Box className="total-item">
                            <Typography variant="axt">
                              {t("my_basket_total")}
                            </Typography>
                            <Typography variant="bxt">
                              €{formatMoney(basketContext.netAmount)}
                            </Typography>
                          </Box>
                          <Box className="total-item">
                            <Typography variant="axt">
                              {t("total_tax")}
                            </Typography>
                            <Typography variant="bxt">
                              €
                              {formatMoney(
                                basketContext.totalAmount -
                                  basketContext.netAmount
                              )}
                            </Typography>
                          </Box>
                          <Box className="total-item">
                            <Typography variant="axt">
                              {t("total_price")}
                            </Typography>
                            <Typography variant="bxt">
                              € {formatMoney(basketContext.getTotalPrice())}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                </div>
              </Box>
              <Box>
                {!props.showDetail && (
                  <Button
                    className="add-product"
                    variant="outlined"
                    fullWidth
                    sx={{
                      textTransform: "none",
                      pt: "14px",
                      pb: "14px",
                      borderRadius: "8px",
                      mb: 1,
                    }}
                    onClick={() => {
                      backHome();
                    }}
                  >
                    {t("back_home")}
                  </Button>
                )}

                {!props.showDetail &&
                  basketContext.items != null &&
                  basketContext.items.length > 0 && (
                    <Button
                      className="continue"
                      variant="contained"
                      fullWidth
                      sx={{
                        textTransform: "none",
                        pt: "14px",
                        pb: "14px",
                        borderRadius: "8px",
                      }}
                      onClick={() => {
                        passPayment();
                      }}
                    >
                      {t("pass_payment")}
                    </Button>
                  )}

                {props.showDetail && (
                  <>
                    {false && (
                      <Button
                        className="add-product"
                        variant="outlined"
                        fullWidth
                        sx={{
                          textTransform: "none",
                          pt: "14px",
                          pb: "14px",
                          borderRadius: "8px",
                          mb: 1,
                        }}
                        onClick={() => {
                          backBasket();
                        }}
                      >
                        {t("back_basket")}
                      </Button>
                    )}

                    <Button
                      className="continue"
                      variant="contained"
                      fullWidth
                      sx={{
                        textTransform: "none",
                        pt: "14px",
                        pb: "14px",
                        borderRadius: "8px",
                        mt: 1,
                      }}
                      onClick={() => {
                        passPayment();
                      }}
                    >
                      {t("complete_order")}
                    </Button>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      {!props.showDetail && false && (
        <Box className="herox">
          <Grid className="our-picks">
            <Container>
              <div className="title">Our Picks For You</div>
              <Grid className="my-slider">
                {products.map((product) => {
                  return (
                    <ProductItem
                      key={"productItem_subCategory_" + product.id}
                      product={product}
                    />
                  );
                })}
              </Grid>
            </Container>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default Basket;
