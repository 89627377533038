import { Box, Chip } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { brandService } from "../../services/brandService";
import ProductContext from "../../store/product-context";
import "./BrandBar.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";


function BrandBar(props) {
  const previousController = useRef();
  const { productContext } = useContext(ProductContext);
  const [brands, setBrands] = useStateIfMounted([]);
  const [brandId, setBrandId] = useStateIfMounted(null);
  const [hasChanged, setHasChanged] = useStateIfMounted(true);

  const [showRightArrow, setShowRightArrow] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const boxRef = useRef(null);

  useEffect(() => {    
    const handleScroll = () => {
      const box = boxRef.current;
      const isScrollable = box.scrollWidth > box.clientWidth;
      const atRightEnd = box.scrollLeft + box.clientWidth >= box.scrollWidth;
      const atLeftEnd = box.scrollLeft <= 0;

      if (isScrollable) {
        setShowRightArrow(!atRightEnd);
        setShowLeftArrow(!atLeftEnd);
      } else {
        setShowRightArrow(false);
        setShowLeftArrow(false);
      }
    };

    handleScroll(); // check on mount
    boxRef.current.addEventListener("scroll", handleScroll); // check on scroll
    window.addEventListener("resize", handleScroll); // check on resize
    return () => {
      boxRef.current?.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, [brands]);

  const scrollRight = () => {
    boxRef.current.scrollBy({ left: 100, behavior: "smooth" });
  };

  const scrollLeft = () => {
    boxRef.current.scrollBy({ left: -100, behavior: "smooth" });
  };

  useEffect(() => {
    if (!hasChanged) {
      setHasChanged(true);
      return;
    }

    const body = {};

    const categorySubCategoryInfo = productContext.getProductState(
      "categorySubCategoryInfo"
    );

    const search = productContext.getProductState("search");
    const page = productContext.getProductState("page");
    const favorite = productContext.getProductState("favorite");

    if (
      categorySubCategoryInfo !== undefined &&
      categorySubCategoryInfo.categoryId
    ) {
      body["categoryId"] = categorySubCategoryInfo.categoryId;
    }

    if (
      categorySubCategoryInfo !== undefined &&
      categorySubCategoryInfo.subCategoryId
    ) {
      body["subCategoryIdList"] = [categorySubCategoryInfo.subCategoryId];
    }
    if (search) {
      body["searchKeyword"] = search;
    }
    if (favorite) {
      body["favorite"] = favorite;
    }
    if (page != null) {
      const controller = new AbortController();
      const signal = controller.signal;
      previousController.current = controller;
      brandService.getBrands(body, { signal }).then((response) => {
        const brandsResponse = response.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        setBrands(brandsResponse);
        const brandId = productContext.getProductState("brandId");
        setBrandId(brandId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productContext]);

  const brandChangeHandler = (_brandId) => {
    if (_brandId === brandId) {
      _brandId = null;
    } else {
      setHasChanged(false);
    }
    setBrandId(_brandId);
    productContext.setProductState("brandId", _brandId);

    props.setShowSlider(false);
  };

  return (
    <div className="brand-container">
      {showLeftArrow && (
        <ArrowBackIosIcon
          onClick={scrollLeft}
          style={{
            zIndex:1,
            position: "absolute",
            left: 0,
            top: "40%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            background: "white",
            borderRadius: "50%",
            padding: "5px",
            zIndex: 1,
            border:"2px solid #eee"
          }}
        />
      )}

      <Box
        ref={boxRef}
        sx={{
          mb: 1,
          pb: 1,
          display: "flex",
          width: "100%",
          overflowX: "auto",
          overflowY: "hidden",
          whiteSpace: "nowrap",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": { display: "none" },
        }}
        className="brand-bar"
      >
        {brands !== undefined &&
          brands.map((brand) => (
            <Chip
              key={"brand_chip_" + brand.id}
              label={brand.name}
              sx={{ mr: 1 }}
              onClick={() => brandChangeHandler(brand.id)}
            />
          ))}
      </Box>

      {showRightArrow && (
        <ArrowForwardIosIcon
          onClick={scrollRight}
          style={{
            zIndex:1,
            position: "absolute",
            right: 0,
            top: "40%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            background: "white",
            borderRadius: "50%",
            padding: "5px",
            border:"2px solid #eee"
          }}
        />
      )}
    </div>
  );
}

export default BrandBar;
