import { useSnackbar } from "notistack";
import { useContext, useReducer } from "react";
import { orderService } from "../services/orderService";
import BasketContext from "./basket-context";
import LoginContext from "./login-context";

const basketReducer = (state, action) => {
  let updatedItems = state.items;
  if (action.type === "CLEAR") {
    updatedItems = [];
  } else if (action.type === "SET_ORDER_INFO") {
    state.netAmount = action.netAmount;
    state.discountAmount = action.discountAmount;
    state.deliveryCostInfo = action.deliveryCostInfo;
    state.taxAmounts = action.taxAmounts;
    state.totalAmount = action.totalAmount;
  } else if (action.type === "SET_PROCESS") {
    state.processItems = { ...action.processItems };
  } else if (action.type === "SET_LIST") {
    state.counter = action.counter;

    if (action.item.length === 0) {
      updatedItems = [];
    }

    for (let basketItem of action.item) {
      const existingBasketItemIndex = updatedItems.findIndex(
        (item) =>
          item.productId === basketItem.productId &&
          item.salesUnitItemId === basketItem.salesUnitItemId
      );

      if (basketItem.count === 0) {
        updatedItems = updatedItems.filter((m, i) => {
          return i !== existingBasketItemIndex;
        });
      } else {
        if (existingBasketItemIndex > -1) {
          updatedItems[existingBasketItemIndex].count = basketItem.count;
          updatedItems[existingBasketItemIndex].price = basketItem.price;
          updatedItems[existingBasketItemIndex].salesUnitItemUnitPrice =
            basketItem.salesUnitItemUnitPrice;
          updatedItems[existingBasketItemIndex].defaultPrice =
            basketItem.defaultPrice;
          updatedItems[existingBasketItemIndex].defaultUnitPrice =
            basketItem.defaultUnitPrice;
        } else {
          updatedItems.push(basketItem);
        }
      }
    }
  } else if (action.type === "SET") {
    state.counter = action.counter;
    const existingBasketItemIndex = updatedItems.findIndex(
      (item) =>
        item.productId === action.item.productId &&
        item.salesUnitItemId === action.item.salesUnitItemId
    );
    if (action.item.count === 0) {
      updatedItems.splice(existingBasketItemIndex, 1);
    } else {
      if (existingBasketItemIndex > -1) {
        updatedItems[existingBasketItemIndex].count = action.item.count;
        updatedItems[existingBasketItemIndex].price = action.item.price;
        updatedItems[existingBasketItemIndex].salesUnitItemUnitPrice =
          action.item.salesUnitItemUnitPrice;
        updatedItems[existingBasketItemIndex].defaultPrice =
          action.item.defaultPrice;
        updatedItems[existingBasketItemIndex].defaultUnitPrice =
          action.item.defaultUnitPrice;
      } else {
        updatedItems.push(action.item);
      }
    }
  }

  state.items = updatedItems;
  return { ...state };
};

const BasketProvider = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { loginContext } = useContext(LoginContext);
  let defaultBasketState = { items: [], processItems: {}, counter: 0 };

  const [basketState, dispatchBasketAction] = useReducer(
    basketReducer,
    defaultBasketState
  );

  const setItemToBasketHandler = (
    tax,
    productId,
    productName,
    productUnit,
    imageUrl,
    salesUnitItemId,
    salesUnitItemUnitName,
    salesUnitItemPiece,
    salesUnitItemUnitPrice,
    price,
    count
  ) => {
    if (loginContext.getLoginState("user")) {
      return sendItem(productId, salesUnitItemId, count)
        .then((response) => {
          if (response != null && response.length > 0) {
            let items = [];
            for (let item of response) {
              const sameProduct =
                item.productId === productId &&
                item.salesUnitId === salesUnitItemId;
              items.push({
                productId: item.productId,
                salesUnitItemId: item.salesUnitId,
                price: item.price,
                salesUnitItemUnitPrice: item.unitPrice,
                count: item.piece,
                defaultPrice: item.defaultPrice,
                defaultUnitPrice: item.defaultUnitPrice,

                productName: sameProduct ? productName : undefined,
                productUnit: sameProduct ? productUnit : undefined,
                imageUrl: sameProduct ? imageUrl : undefined,
                salesUnitItemUnitName: sameProduct
                  ? salesUnitItemUnitName
                  : undefined,
                salesUnitItemPiece: sameProduct
                  ? salesUnitItemPiece
                  : undefined,
              });
            }

            dispatchBasketAction({
              type: "SET_LIST",
              item: items,
              counter: count,
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(error, {
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    } else {
      dispatchBasketAction({
        type: "SET",
        item: {
          tax,
          productId,
          productName,
          productUnit,
          imageUrl,
          salesUnitItemId,
          salesUnitItemUnitName,
          salesUnitItemPiece,
          salesUnitItemUnitPrice,
          price,
          count,
          defaultPrice: price,
        },
        counter: count,
      });

      localStorage.setItem("basket", JSON.stringify(basketState));
    }
  };

  const setItemsToBasketHandler = (items) => {
    dispatchBasketAction({
      type: "SET_LIST",
      item: items,
      counter: basketState.counter,
    });

    if (loginContext.getLoginState("user")) {
      return sendItems(items);
    } else {
      localStorage.setItem("basket", JSON.stringify(basketState));
      return new Promise(function (resolve, reject) {
        resolve(basketState.items);
      });
    }
  };

  const clearItemsToBasketHandler = () => {
    const body = JSON.parse(
      JSON.stringify(
        basketState.items.map((item) => {
          return {
            productId: item.productId,
            salesUnitItemId: item.salesUnitItemId,
            count: 0,
          };
        })
      )
    );

    dispatchBasketAction({
      type: "CLEAR",
    });
    if (loginContext.getLoginState("user")) {
      return sendItems(body);
    } else {
      localStorage.removeItem("basket");
      return new Promise(function (resolve, reject) {
        resolve();
      });
    }
  };

  const sendItem = (productId, salesUnitItemId, count) => {
    const body = {
      productId,
      salesUnitId: salesUnitItemId,
      count,
    };
    return orderService.setBasketItem(body);
  };

  const sendItems = (items) => {
    const body = items.map((item) => {
      return {
        productId: item.productId,
        salesUnitId: item.salesUnitItemId,
        count: item.count,
      };
    });
    return orderService.setBasketItems(body);
  };

  const sendItemsHandler = () => {
    return sendItems(basketState.items);
  };

  const getItemsByServiceHandler = (force = false) => {
    if (loginContext.getLoginState("user") || force) {
      orderService.getBasketItems().then((response) => {
        if (response.orderProducts != null) {
          const items = response.orderProducts.map((m) => {
            return {
              productId: m.productId,
              productName: m.name,
              productUnit: m.productUnit,
              imageUrl: m.image,
              salesUnitItemId: m.salesUnitId,
              salesUnitItemUnitName: m.salesUnitName,
              salesUnitItemPiece: m.salesUnitPiece,
              salesUnitItemUnitPrice: m.salesUnitUnitPrice,
              count: m.piece,
              price: m.salesUnitPrice,
              defaultPrice: m.defaultPrice,
              defaultUnitPrice: m.defaultUnitPrice,
            };
          });
          dispatchBasketAction({
            type: "SET_LIST",
            item: items,
            counter: basketState.counter,
          });
        } else {
          dispatchBasketAction({
            type: "SET_LIST",
            item: [],
            counter: 0,
          });
        }

        dispatchBasketAction({
          type: "SET_ORDER_INFO",
          netAmount: response.netAmount,
          discountAmount: response.discountAmount,
          taxAmounts: response.taxAmounts,
          totalAmount: response.totalAmount,
        });
      });
    } else {
      const basketStateByStorage = JSON.parse(localStorage.getItem("basket"));
      if (basketStateByStorage != null) {
        dispatchBasketAction({
          type: "SET_LIST",
          item: basketStateByStorage.items,
          counter: basketStateByStorage.counter,
        });

        let netAmount = 0;
        let taxAmounts = 0;

        basketStateByStorage.items.map((x) => {
          netAmount =
            netAmount +
            x.count * x.salesUnitItemUnitPrice * x.salesUnitItemPiece;
          taxAmounts =
            taxAmounts +
            x.count *
              x.salesUnitItemUnitPrice *
              x.salesUnitItemPiece *
              (x.tax / 100);

          return null;
        });

        dispatchBasketAction({
          type: "SET_ORDER_INFO",
          netAmount: netAmount,
          discountAmount: 0,
          taxAmounts: taxAmounts,
          totalAmount: netAmount + taxAmounts,
        });
      }
    }
  };

  const getOrderConfirmationDataHandler = () => {
    if (loginContext.getLoginState("user")) {
      orderService.getBasketConfirmation().then((response) => {
        if (response.orderProducts != null) {
          const items = response.orderProducts.map((m) => {
            return {
              productId: m.productId,
              productName: m.name,
              productUnit: m.productUnit,
              imageUrl: m.image,
              salesUnitItemId: m.salesUnitId,
              salesUnitItemUnitName: m.salesUnitName,
              salesUnitItemPiece: m.salesUnitPiece,
              salesUnitItemUnitPrice: m.salesUnitUnitPrice,
              count: m.piece,
              price: m.salesUnitPrice,
              defaultPrice: m.defaultPrice,
              defaultUnitPrice: m.defaultUnitPrice,
            };
          });
          dispatchBasketAction({
            type: "SET_LIST",
            item: items,
            counter: basketState.counter,
          });
        } else {
          dispatchBasketAction({
            type: "SET_LIST",
            item: [],
            counter: basketState.counter,
          });
        }

        dispatchBasketAction({
          type: "SET_ORDER_INFO",
          netAmount: response.netAmount,
          discountAmount: response.discountAmount,
          deliveryCostInfo: response.deliveryCostInfo,
          taxAmounts: response.taxAmounts,
          totalAmount: response.totalAmount,
        });
      });
    }
  };

  const getItemToBasketHandler = (productId, salesUnitItemId) => {
    const existingBasketItemIndex = basketState.items.findIndex(
      (item) =>
        item.productId === productId && item.salesUnitItemId === salesUnitItemId
    );
    let existingBasketItem = basketState.items[existingBasketItemIndex];
    return existingBasketItem ? existingBasketItem.count : 0;
  };

  const getBadgeCountHandler = () => {
    if (basketState.items != null) {
      return basketState.items.length;
    }
    return 0;
  };

  const getTotalPriceHandler = () => {
    if (basketState.items != null) {
      const totalSum = basketState.items
        .map((m) => parseFloat(parseFloat(m.count * m.price).toFixed(2)))
        .reduce((partialSum, a) => partialSum + a, 0);
      return parseFloat(totalSum).toFixed(2);
    }
    return parseFloat(0).toFixed(2);
  };

  const getDiscountPriceHandler = () => {
    if (basketState.items != null) {
      const totalSum = basketState.discountAmount;
      return parseFloat(totalSum).toFixed(2);
    }
    return parseFloat(0).toFixed(2);
  };

  const getDeliveryCostHandler = () => {
    return basketState.deliveryCostInfo;
  };

  const clearPrices = () => {
    dispatchBasketAction({
      type: "SET_ORDER_INFO",
      netAmount: null,
      discountAmount: null,
      deliveryCostInfo: null,
      taxAmounts: null,
      totalAmount: null,
    });
  };

  const basketContext = {
    items: basketState.items,
    netAmount: basketState.netAmount,
    discountAmount: basketState.discountAmount,
    deliveryCostInfo: basketState.deliveryCostInfo,
    totalAmount: basketState.totalAmount,
    taxAmounts: basketState.taxAmounts,
    processItems: [],
    setItem: setItemToBasketHandler,
    setItems: setItemsToBasketHandler,
    clearItems: clearItemsToBasketHandler,
    getItem: getItemToBasketHandler,
    getTotalPrice: getTotalPriceHandler,
    getDiscountPrice: getDiscountPriceHandler,
    getDeliveryCost: getDeliveryCostHandler,
    getBadgeCount: getBadgeCountHandler,
    sendItems: sendItemsHandler,
    getItemsByService: getItemsByServiceHandler,
    getOrderConfirmationData: getOrderConfirmationDataHandler,
    counter: basketState.counter ?? 0,
    clearPrices: clearPrices,
  };

  return (
    <BasketContext.Provider value={{ basketContext }}>
      {props.children}
    </BasketContext.Provider>
  );
};

export default BasketProvider;
