import {
  CircularProgress,
  Grid,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import ProductItem from "../../components/ProductItem/ProductItem";
import { productService } from "../../services/productService";
import ProductContext from "../../store/product-context";
import "./Product.css";
import Advertising from "../../components/Advertising/Advertising";
import BrandBar from "../../components/BrandBar/BrandBar";

function Product() {
  const previousController = useRef();
  const { productContext } = useContext(ProductContext);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paged, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const { t } = useTranslation();
  const [showSlider, setShowSlider] = useState(true);

  const setCurrentPage = (e) => {
    if (e.target.className === "ri-arrow-left-double-line") {
      setPage(1);
    } else if (e.target.className === "ri-arrow-left-s-line") {
      setPage(paged - 1);
    } else if (e.target.className === "ri-arrow-right-s-line") {
      setPage(paged + 1);
    } else if (e.target.className === "ri-arrow-right-double-line") {
      setPage(totalPage);
    } else {
      setPage(e.target.textContent);
    }
  };

  let perPage = productContext.getProductState("perPage") ?? 50;

  const fetchProducts = useCallback(() => {
    setLoading(true);
    const page = productContext.getProductState("page");
    if (page === undefined) {
      productContext.setProductState("page", 0);
      return;
    }

    const body = { page: page, size: perPage };

    let categoriesByStorage = JSON.parse(sessionStorage.getItem("categories"));
    if (categoriesByStorage == null) {
      setTimeout(fetchProducts.bind(this), 500);
      return;
    }
    setCategories(categoriesByStorage);

    let categorySubCategoryInfo = productContext.getProductState(
      "categorySubCategoryInfo"
    );

    const brandId = productContext.getProductState("brandId");
    const search = productContext.getProductState("search");

    let firstCat = false;
    if((categorySubCategoryInfo === undefined || !categorySubCategoryInfo) && (brandId === undefined || !brandId) && (search === undefined || !search)) {
      categorySubCategoryInfo = {
        categoryId: categoriesByStorage[0]?.id,
        subCategoryId: undefined,
      }

      firstCat = true;
    }

    if (
      categorySubCategoryInfo !== undefined &&
      categorySubCategoryInfo.categoryId
    ) {
      body["categoryId"] = categorySubCategoryInfo.categoryId;
    }
    if (
      categorySubCategoryInfo !== undefined &&
      categorySubCategoryInfo.subCategoryId
    ) {
      body["subCategoryIdList"] = [categorySubCategoryInfo.subCategoryId];
    }
    if (brandId) {
      body["brandId"] = brandId;
    }
    if (search) {
      body["searchKeyword"] = search;
    }
    if (previousController.current) {
      previousController.current.abort();
    }

    if((body["categoryId"] || body["subCategoryIdList"] || body["searchKeyword"]) && firstCat === false) {
      setShowSlider(false);
    }else {
      if(!productContext.getProductState("brandId")){
        setShowSlider(true);
      }
    }

    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    productService.getProducts(body, { signal }).then((response) => {
      setTotalPage(response.totalPages);
      setProducts([]);
      setProducts((p) => [...p, ...response.content]);

      setLoading(false);
    }).catch((error) => {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Fetch error:', error);
        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productContext, perPage]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    productContext.setProductState("page", paged - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paged]);

  const productCount = (event) => {
    productContext.setProductState("perPage", event.target.value);
  };

  return (
    <div style={{ width: "100%" }} id="productGrid">
      {showSlider && <Advertising />}
      
      <BrandBar  setShowSlider={setShowSlider} />
      <>
        {!loading && (products == null || products.length === 0) && (
          <div className="product_not_found">
            <div>
              <strong>{t("product_not_found")}</strong>
            </div>
            {t("product_not_found_body")}
          </div>
        )}
        {(products != null || products.length !== 0) && (
          <div className="home-p-grid">
            <div className="h-product-counter">
              <Select onChange={productCount} value={perPage}>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </div>
            {categories != null &&
              categories.length > 0 &&
              categories.map((category) => {
                return (
                  products.filter((f) => f.categoryId === category.id).length >
                    0 && (
                    <div key={category.name}>
                      <div className="big-cat-title">
                        {category.subCategories.length === 0 && (
                          <div
                            className="category-name"
                            onClick={() => {
                              productContext.setProductState(
                                "categorySubCategoryInfo",
                                {
                                  categoryId: category.id,
                                  subCategoryId: undefined,
                                }
                              );
                            }}
                          >
                            {category.name}
                          </div>
                        )}
                      </div>
                      {products.filter(
                        (f) =>
                          f.categoryId === category.id &&
                          (f.subCategoryId === null ||
                            f.subCategoryId === undefined)
                      ).length > 0 && (
                        <div sx={{ mb: 2, mt: 2 }} className="home-grid">
                          {products
                            .filter(
                              (f) =>
                                f.categoryId === category.id &&
                                (f.subCategoryId === null ||
                                  f.subCategoryId === undefined)
                            )
                            .map((product) => {
                              return (
                                <>
                                  <div
                                    className="home-grid-item"
                                    key={
                                      "productItem_category_grid" + product.id
                                    }
                                  >
                                    <ProductItem
                                      categoryName={category.name}
                                      key={"productItem_category_" + product.id}
                                      product={product}
                                    />
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      )}
                      {category.subCategories != null &&
                        category.subCategories.length > 0 &&
                        category.subCategories.map((subCategory) => {
                          return (
                            products.filter(
                              (f) =>
                                f.categoryId === category.id &&
                                f.subCategoryId === subCategory.id
                            ).length > 0 && (
                              <div key={subCategory.name}>
                                <div className="big-cat-title">
                                  <div
                                    className="category-name"
                                    onClick={() => {
                                      productContext.setProductState(
                                        "categorySubCategoryInfo",
                                        {
                                          categoryId: category.id,
                                          subCategoryId: undefined,
                                        }
                                      );
                                    }}
                                  >
                                    {category.name}
                                  </div>
                                  <div className="category-desc">
                                    {subCategory.name}
                                  </div>
                                </div>
                                <div
                                  className="home-grid"
                                  sx={{ mb: 2, mt: 2 }}
                                >
                                  {products
                                    .filter(
                                      (f) =>
                                        f.categoryId === category.id &&
                                        f.subCategoryId === subCategory.id
                                    )
                                    .map((product) => {
                                      return (
                                        <div
                                          className="home-grid-item"
                                          key={
                                            "productItem_subCategory_grid" +
                                            product.id
                                          }
                                        >
                                          <ProductItem
                                            key={
                                              "productItem_subCategory_" +
                                              product.id
                                            }
                                            product={product}
                                          />
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            )
                          );
                        })}
                    </div>
                  )
                );
              })}

            <div className="pagination">
              <Pagination
                count={totalPage}
                onChange={setCurrentPage}
                renderItem={(item) => (
                  <PaginationItem
                    components={{
                      last: (props) => (
                        <i className="ri-arrow-right-double-line" />
                      ),
                      next: (props) => <i className="ri-arrow-right-s-line" />,
                      first: (props) => (
                        <i className="ri-arrow-left-double-line" />
                      ),
                      previous: (props) => (
                        <i className="ri-arrow-left-s-line" />
                      ),
                    }}
                    {...item}
                  />
                )}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        )}
      </>
      {loading ? (
        <Grid item xs key="loading" sx={{ textAlign: "center", mt: 2 }}>
          <CircularProgress />
        </Grid>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Product;
