import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProductContext from "../../store/product-context";
import SidebarSubItem from "./SidebarSubItem";
import "./Sidebar.css";

function SidebarItem(props) {
  const { productContext } = useContext(ProductContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const category = props.category;
  const subCategory = props.subCategory;
  const item = subCategory ?? category;
  const categorySubCategoryInfo = productContext.getProductState(
    "categorySubCategoryInfo"
  );
  const activeLink =
    subCategory === undefined
      ? categorySubCategoryInfo !== undefined &&
        categorySubCategoryInfo.categoryId === category.id &&
        categorySubCategoryInfo.subCategoryId === undefined
      : categorySubCategoryInfo !== undefined &&
        categorySubCategoryInfo.subCategoryId === subCategory.id;


  const activeTopLink = categorySubCategoryInfo?.categoryId == category?.id

  return (
    <div
      className={
        item.subCategories.length > 0 && open
          ? "sidebar-widget"
          : "sidebar-widget no-white"
      }
    >
      <ListItemButton
        className={"widget-big-title"}
        style={{
          backgroundColor: activeLink || activeTopLink ? "#e5322d" : "initial",
          color: activeLink || activeTopLink ? "#fff !important" : "#E5322D !important",
        }}
      >
        <Link
          className="widget-title"
          underline="none"
          onClick={() => {
            if (location.pathname !== "/") {
              navigate("/");
            }
            if (subCategory === undefined) {
              productContext.setProductState("categorySubCategoryInfo", {
                categoryId: category.id,
                subCategoryId: undefined,
              });
            } else {
              productContext.setProductState("categorySubCategoryInfo", {
                categoryId: category.id,
                subCategoryId: subCategory.id,
              });
            }
          }}
        >
          <ListItemText
            className={
              activeLink || activeTopLink ? "widget-span active-big-title" : "widget-span"
            }
          >
            {item.name}{" "}
          </ListItemText>
        </Link>
        {item.subCategories != null &&
          item.subCategories.length > 0 &&
          (open ? (
            <ExpandLess
              onClick={() => {
                setOpen(!open);
              }}
            />
          ) : (
            <ExpandMore
              onClick={() => {
                setOpen(!open);
              }}
            />
          ))}
      </ListItemButton>
      {item.subCategories != null && item.subCategories.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
      {item.subCategories.map((_subCategory) => {
        return (
          <SidebarSubItem
            key={"sidebar_subCategory_" + _subCategory.id}
            category={category}
            subCategory={_subCategory}
          />
        );
      })}
          </List>
        </Collapse>
      )}
    </div>
  );
}

export default SidebarItem;
