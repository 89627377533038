import { Box } from "@mui/material";
import React, { useState } from "react";
import { config } from "../../services/config";
import "./ProductDetailImageView.css";

function ProductDetailImageView(props) {
  const baseOrigin = config.baseOrigin;
  const images = props.images;
  const [selectedImage, setSelectedImage] = useState(
    images != null && images.length > 0 ? images[0].name : ""
  );

  const [index, setIndex] = useState(0);

  function setIndexIncrementHandler() {
    let selectedIndex;
    if (index === images.length - 1) {
      setIndex(0);
      selectedIndex = 0;
    } else {
      setIndex(index + 1);
      selectedIndex = index + 1;
    }

    setSelectedImage(images[selectedIndex].name);
  }

  function setIndexDecrementHandler() {
    let selectedIndex;
    if (index === 0) {
      setIndex(images.length - 1);
      selectedIndex = images.length - 1;
    } else {
      setIndex(index - 1);
      selectedIndex = index - 1;
    }
    setSelectedImage(images[selectedIndex].name);
  }

  return (
    <Box>
      <div>
        <div className="react-slideshow-container">
          {images != null && images.length > 1 && (
            <div className="img-navbar">
              <button
                className="nav default-nav left"
                onClick={setIndexDecrementHandler}
              >
                <svg width="24" height="24" viewBox="0 0 24 24">
                  <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                </svg>
              </button>
              <button
                className="nav default-nav right"
                onClick={setIndexIncrementHandler}
              >
                <svg width="24" height="24" viewBox="0 0 24 24">
                  <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
                </svg>
              </button>
            </div>
          )}
          <div className="border">
            <Box
              style={{
                backgroundImage: `url(${baseOrigin + selectedImage})`,
                width: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              sx={{ height: { xs: "240px", md: "295px" } }}
            />
          </div>
        </div>
      </div>
    </Box>
  );
}

export default ProductDetailImageView;
