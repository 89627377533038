import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProductContext from "../../store/product-context";
import "./Sidebar.css";

function SidebarSubItem(props) {
  const { productContext } = useContext(ProductContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const category = props.category;
  const subCategory = props.subCategory;
  const item = subCategory ?? category;
  const categorySubCategoryInfo = productContext.getProductState(
    "categorySubCategoryInfo"
  );
  const activeLink =
    subCategory === undefined
      ? categorySubCategoryInfo !== undefined &&
        categorySubCategoryInfo.categoryId === category.id &&
        categorySubCategoryInfo.subCategoryId === undefined
      : categorySubCategoryInfo !== undefined &&
        categorySubCategoryInfo.subCategoryId === subCategory.id;

  return (
    <div className="sidebar-sub">
      <ListItemButton className="new-sub-btn">
        <Link
          padding="0px"
          underline="none"
          onClick={() => {
            if (location.pathname !== "/") {
              navigate("/");
            }
            if (subCategory === undefined) {
              productContext.setProductState("categorySubCategoryInfo", {
                categoryId: category.id,
                subCategoryId: undefined,
              });
            } else {
              productContext.setProductState("categorySubCategoryInfo", {
                categoryId: category.id,
                subCategoryId: subCategory.id,
              });
            }
          }}
        >
          <ListItemText primary={item.name} className={`sub-span sub-span-new ${activeLink ? 'red-color' : ''}`} />
        </Link>
        {item.subCategories != null &&
          item.subCategories.length > 0 &&
          (open ? (
            <ExpandLess
              sx={{ display: { xs: "none", md: "block" } }}
              onClick={() => {
                setOpen(!open);
              }}
            />
          ) : (
            <ExpandMore
              sx={{ display: { xs: "none", md: "block" } }}
              onClick={() => {
                setOpen(!open);
              }}
            />
          ))}
      </ListItemButton>
      {item.subCategories != null && item.subCategories.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" padding="0px">
            {item.subCategories.map((_subCategory) => {
              return (
                <SidebarSubItem
                  key={"sidebar_subCategory_" + _subCategory.id}
                  category={category}
                  subCategory={_subCategory}
                  fontWeight="400"
                />
              );
            })}
          </List>
        </Collapse>
      )}
    </div>
  );
}

export default SidebarSubItem;
