import {
  Checkbox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStateIfMounted } from "use-state-if-mounted";
import { authenticationService } from "../../services/authenticationService";
import { countryService } from "../../services/countryService";
import BasketContext from "../../store/basket-context";
import LoginContext from "../../store/login-context";
import ProductContext from "../../store/product-context";
import "./Login.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Login() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { productContext } = useContext(ProductContext);
  const { basketContext } = useContext(BasketContext);
  const { loginContext } = useContext(LoginContext);

  const [tabValue, setTabValue] = useStateIfMounted(0);

  const [openDialog, setOpenDialog] = useStateIfMounted(false);
  const [countries, setCountries] = useStateIfMounted([]);

  useEffect(() => {
    if (openDialog && countries.length === 0) {
      countryService.getCountries().then((response) => {
        setCountries(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  useEffect(() => {
    setUsername("");
    setUsernameErrorMessage("");
    setPassword("");
    setPasswordErrorMessage("");
    setPasswordAgain("");
    setPasswordAgainErrorMessage("");
    setFullName("");
    setFullNameErrorMessage("");
    setEmail("");
    setEmailErrorMessage("");
    setPhone1("");
    setPhone1ErrorMessage("");
    setPhone2("");
    setPhone2ErrorMessage("");
    setTaxNumber("");
    setTaxNumberErrorMessage("");
    setCountryId("");
    setCountryIdErrorMessage("");
    setZipCode("");
    setZipCodeErrorMessage("");
    setAddress("");
    setAddressErrorMessage("");
    setCity("");
    setCityErrorMessage("");
    setShowPassword(false);
    setShowPasswordAgain(false);
    const openState = loginContext.getLoginState("open");
    setTabValue(loginContext.getLoginState("tabValue"));

    setOpenDialog(openState === undefined ? false : openState);
    setValidate({
      username: { empty: true, valid: false },
      password: { empty: true, valid: false, equal: false },
      passwordAgain: { empty: true, valid: false, equal: false },
      fullName: { empty: true, valid: false },
      email: { empty: true, valid: false },
      phone1: { empty: true, valid: false },
      phone2: { empty: true, valid: true },
      taxNumber: { empty: true, valid: true },
      countryId: { empty: true, valid: false },
      zipCode: { empty: true, valid: false },
      address: { empty: true, valid: false },
      city: { empty: true, valid: true },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginContext]);

  const [validate, setValidate] = useStateIfMounted({
    username: { empty: true, valid: false },
    password: { empty: true, valid: false, equal: false },
    passwordAgain: { empty: true, valid: false, equal: false },
    fullName: { empty: true, valid: false },
    email: { empty: true, valid: false },
    phone1: { empty: true, valid: false },
    phone2: { empty: true, valid: true },
    taxNumber: { empty: true, valid: true },
    countryId: { empty: true, valid: false },
    zipCode: { empty: true, valid: false },
    address: { empty: true, valid: false },
    city: { empty: true, valid: true },
  });
  const [username, setUsername] = useStateIfMounted("");
  const [usernameErrorMessage, setUsernameErrorMessage] = useStateIfMounted("");
  const [password, setPassword] = useStateIfMounted("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useStateIfMounted("");
  const [passwordAgain, setPasswordAgain] = useStateIfMounted("");
  const [passwordAgainErrorMessage, setPasswordAgainErrorMessage] =
    useStateIfMounted("");
  //
  const [showPassword, setShowPassword] = useStateIfMounted(false);
  const [showPasswordAgain, setShowPasswordAgain] = useStateIfMounted(false);
  //
  const [fullName, setFullName] = useStateIfMounted("");
  const [fullNameErrorMessage, setFullNameErrorMessage] = useStateIfMounted("");
  const [email, setEmail] = useStateIfMounted("");
  const [emailErrorMessage, setEmailErrorMessage] = useStateIfMounted("");
  const [phone1, setPhone1] = useStateIfMounted("");
  const [phone1ErrorMessage, setPhone1ErrorMessage] = useStateIfMounted("");
  const [phone2, setPhone2] = useStateIfMounted("");
  const [phone2ErrorMessage, setPhone2ErrorMessage] = useStateIfMounted("");
  const [taxNumber, setTaxNumber] = useStateIfMounted("");
  const [taxNumberErrorMessage, setTaxNumberErrorMessage] =
    useStateIfMounted("");
  const [countryId, setCountryId] = useStateIfMounted("");
  const [countryIdErrorMessage, setCountryIdErrorMessage] =
    useStateIfMounted("");
  const [zipCode, setZipCode] = useStateIfMounted("");
  const [zipCodeErrorMessage, setZipCodeErrorMessage] = useStateIfMounted("");
  const [address, setAddress] = useStateIfMounted("");
  const [addressErrorMessage, setAddressErrorMessage] = useStateIfMounted("");
  const [city, setCity] = useStateIfMounted("");
  const [cityErrorMessage, setCityErrorMessage] = useStateIfMounted("");

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function checkEmpty(value, property, propertyName, setterFunction) {
    let validateTmp = { ...validate };
    if (value === "") {
      setterFunction(t("required").replace("{property}", propertyName));
      validateTmp[property] = { ...validateTmp[property], empty: true };
    } else {
      setterFunction("");
      validateTmp[property] = { ...validateTmp[property], empty: false };
    }
    setValidate(validateTmp);
  }

  const usernameRegex = /^[a-zA-Z0-9][a-zA-Z0-9]{2,}/;
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\S+$).{6,}$/;
  const fullNameRegex = /^[a-zA-Z]{3,}(?: [a-zA-Z]+){0,2}/;
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*/;
  const phoneRegex = /^[0-9 ]+/;
  const zipCodeRegex = /^[0-9]+/;
  const addressRegex = /^[a-zA-Z0-9 ]{2,}/;

  function checkRegex(
    value,
    property,
    regex,
    message,
    setterFunction,
    canBeEmpty
  ) {
    let validateTmp = { ...validate };

    if (value === "") {
      if (canBeEmpty) {
        validateTmp[property] = { empty: true, valid: true };
        setterFunction("");
        setValidate(validateTmp);
      }
      return;
    }

    if (!value.match(regex)) {
      setterFunction(message);
      validateTmp[property] = { empty: false, valid: false };
    } else {
      setterFunction("");
      validateTmp[property] = { empty: false, valid: true };
    }
    setValidate(validateTmp);
  }

  function comparePassword(password, passwordAgain) {
    if (password === "" || passwordAgain === "") {
      return;
    }
    let validateTmp = { ...validate };

    if (!password.match(passwordRegex) || !passwordAgain.match(passwordRegex)) {
      return;
    }

    if (password !== passwordAgain) {
      setPasswordErrorMessage(t("not_match_password"));
      setPasswordAgainErrorMessage(t("not_match_password"));
      validateTmp["password"] = { empty: false, valid: true, equal: false };
      validateTmp["passwordAgain"] = {
        empty: false,
        valid: true,
        equal: false,
      };
    } else {
      setPasswordErrorMessage("");
      setPasswordAgainErrorMessage("");
      validateTmp["password"] = { empty: false, valid: true, equal: true };
      validateTmp["passwordAgain"] = {
        empty: false,
        valid: true,
        equal: true,
      };
    }
    setValidate(validateTmp);
  }

  function formSubmit() {
    if (tabValue === 0) {
      if (validate.username.empty || validate.password.empty) {
        if (validate.username.empty) {
          setUsernameErrorMessage(
            t("required").replace("{property}", t("username"))
          );
        }
        if (validate.password.empty) {
          setPasswordErrorMessage(
            t("required").replace("{property}", t("password"))
          );
        }
        return;
      }
      login();
    } else {
      if (
        validate.username.empty ||
        !validate.username.valid ||
        validate.password.empty ||
        !validate.password.valid ||
        validate.passwordAgain.empty ||
        !validate.passwordAgain.valid ||
        !validate.password.equal ||
        !validate.passwordAgain.equal ||
        validate.fullName.empty ||
        !validate.fullName.valid ||
        validate.email.empty ||
        !validate.email.valid ||
        validate.phone1.empty ||
        !validate.phone1.valid ||
        !validate.phone2.valid ||
        !validate.taxNumber.valid ||
        validate.countryId.empty ||
        validate.zipCode.empty ||
        !validate.zipCode.valid ||
        validate.address.empty ||
        !validate.address.valid ||
        validate.city.empty ||
        !validate.city.valid
      ) {
        if (validate.username.empty) {
          setUsernameErrorMessage(
            t("required").replace("{property}", t("username"))
          );
        }

        if (validate.password.empty) {
          setPasswordErrorMessage(
            t("required").replace("{property}", t("password"))
          );
        }

        if (validate.passwordAgain.empty) {
          setPasswordAgainErrorMessage(
            t("required").replace("{property}", t("password_again"))
          );
        }

        if (validate.fullName.empty) {
          setFullNameErrorMessage(
            t("required").replace("{property}", t("fullName"))
          );
        }

        if (validate.email.empty) {
          setEmailErrorMessage(t("required").replace("{property}", t("email")));
        }

        if (validate.phone1.empty) {
          setPhone1ErrorMessage(
            t("required").replace("{property}", t("phone1"))
          );
        }

        if (validate.countryId.empty) {
          setCountryIdErrorMessage(
            t("required").replace("{property}", t("country"))
          );
        }

        if (validate.zipCode.empty) {
          setZipCodeErrorMessage(
            t("required").replace("{property}", t("zipCode"))
          );
        }

        if (validate.address.empty) {
          setAddressErrorMessage(
            t("required").replace("{property}", t("address"))
          );
        }

        if (validate.city.empty) {
          setCityErrorMessage(t("required").replace("{property}", t("city")));
        }
        return;
      }
      register();
    }
  }

  function login() {
    setOpenDialog(false);
    authenticationService
      .login(username, password)
      .then((response) => {
        sendBasket(response);
        enqueueSnackbar(t("success_login"), {
          variant: "success",
          autoHideDuration: 2000,
        });
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  }

  function register() {
    setOpenDialog(false);
    authenticationService
      .register(
        username,
        password,
        passwordAgain,
        fullName,
        email,
        phone1,
        phone2,
        taxNumber,
        countryId,
        zipCode,
        address,
        city
      )
      .then(() => {
        enqueueSnackbar(t("success_register"), {
          variant: "success",
          autoHideDuration: 2000,
        });
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  }

  function sendBasket(response) {
    loginContext.setLoginState("open", false);
    loginContext.setLoginState("user", response);
    basketContext.sendItems().then(() => {
      localStorage.removeItem("basket");
      basketContext.getItemsByService(true);
      productContext.setProductState("page", 0);
    });
  }

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
    if (newValue === 0) {
      setUsernameErrorMessage("");
      setPasswordErrorMessage("");
    }
  }

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      onClose={() => {
        loginContext.setLoginState("open", false);
      }}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: tabValue === 0 ? "500px" : "900px",
            borderRadius: "16px",
          },
        },
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formSubmit();
        }}
      >
        <div className="form-head">
          <h3>{tabValue === 1 ? t("register_title") : t("login_title")}</h3>
          <i
            className="ri-close-line"
            onClick={() => {
              loginContext.setLoginState("open", false);
            }}
          />
        </div>
        <DialogContent>
          <div className="login-page-container">
            {tabValue === 1 && (
              <>
                <Box
                  sx={{
                    textAlign: "left",
                    width: "100%",
                    color: "#151518",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 600,
                      color: "#151518",
                    }}
                  >
                    {t("contact_info")}
                  </Typography>
                </Box>
                <div className="form-row">
                  <TextField
                    error={fullNameErrorMessage !== ""}
                    helperText={fullNameErrorMessage}
                    fullWidth
                    margin="dense"
                    id="fullName"
                    label={t("fullName")}
                    onChange={(event) => {
                      checkEmpty(
                        event.target.value,
                        "fullName",
                        t("fullName"),
                        setFullNameErrorMessage
                      );

                      checkRegex(
                        event.target.value,
                        "fullName",
                        fullNameRegex,
                        t("fullName_not_valid"),
                        setFullNameErrorMessage
                      );
                      setFullName(event.target.value);
                    }}
                  />

                  <TextField
                    error={taxNumberErrorMessage !== ""}
                    helperText={taxNumberErrorMessage}
                    fullWidth
                    margin="dense"
                    id="taxNumber"
                    label={t("taxNumber")}
                    onChange={(event) => {
                      setTaxNumber(event.target.value);
                    }}
                  />
                </div>
              </>
            )}
            <div className={tabValue === 1 ? "form-row" : ""}>
              <TextField
                className="text-input"
                error={usernameErrorMessage !== ""}
                helperText={usernameErrorMessage}
                fullWidth
                margin="dense"
                id="username"
                label={t("username")}
                onChange={(event) => {
                  checkEmpty(
                    event.target.value,
                    "username",
                    t("username"),
                    setUsernameErrorMessage
                  );

                  if (tabValue === 1) {
                    checkRegex(
                      event.target.value,
                      "username",
                      usernameRegex,
                      t("username_not_valid"),
                      setUsernameErrorMessage
                    );
                  }
                  setUsername(event.target.value);
                }}
              />
              {tabValue === 1 ? (
                <TextField
                  error={emailErrorMessage !== ""}
                  helperText={emailErrorMessage}
                  fullWidth
                  margin="dense"
                  id="email"
                  label={t("email")}
                  onChange={(event) => {
                    checkEmpty(
                      event.target.value,
                      "email",
                      t("email"),
                      setEmailErrorMessage
                    );

                    checkRegex(
                      event.target.value,
                      "email",
                      emailRegex,
                      t("email_not_valid"),
                      setEmailErrorMessage
                    );
                    setEmail(event.target.value);
                  }}
                />
              ) : (
                <TextField
                  className="text-input"
                  error={passwordErrorMessage !== ""}
                  helperText={passwordErrorMessage}
                  fullWidth
                  margin={tabValue === 1 ? "dense" : "densex"}
                  id="password"
                  label={t("password")}
                  type={showPassword ? "text" : "password"}
                  onChange={(event) => {
                    checkEmpty(
                      event.target.value,
                      "password",
                      t("password"),
                      setPasswordErrorMessage
                    );

                    if (tabValue === 1) {
                      checkRegex(
                        event.target.value,
                        "password",
                        passwordRegex,
                        t("password_not_valid"),
                        setPasswordErrorMessage
                      );
                      comparePassword(event.target.value, passwordAgain);
                    }
                    setPassword(event.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          className="eye-icon"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? (
                            <i className="ri-eye-off-line" />
                          ) : (
                            <i className="ri-eye-line" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </div>

            {tabValue === 1 && (
              <>
                <div className="form-row">
                  <TextField
                    error={phone1ErrorMessage !== ""}
                    helperText={phone1ErrorMessage}
                    fullWidth
                    margin="dense"
                    id="phone1"
                    label={t("phone1")}
                    inputProps={{ maxLength: 17 }}
                    onChange={(event) => {
                      checkEmpty(
                        event.target.value.trim(),
                        "phone1",
                        t("phone1"),
                        setPhone1ErrorMessage
                      );

                      checkRegex(
                        event.target.value.trim(),
                        "phone1",
                        phoneRegex,
                        t("phone_not_valid"),
                        setPhone1ErrorMessage
                      );
                      setPhone1(event.target.value.trim());
                    }}
                  />

                  <TextField
                    error={phone2ErrorMessage !== ""}
                    helperText={phone2ErrorMessage}
                    fullWidth
                    margin="dense"
                    id="phone2"
                    label={t("phone2")}
                    inputProps={{ maxLength: 17 }}
                    onChange={(event) => {
                      checkRegex(
                        event.target.value.trim(),
                        "phone2",
                        phoneRegex,
                        t("phone_not_valid"),
                        setPhone2ErrorMessage,
                        true
                      );
                      setPhone2(event.target.value.trim());
                    }}
                  />
                </div>
                <Box
                  sx={{
                    pt: 2,
                    textAlign: "left",
                    width: "100%",
                    color: "#151518",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 600,
                      color: "#151518",
                    }}
                  >
                    {t("password")}
                  </Typography>
                </Box>
                <div className="form-row">
                  <TextField
                    className="text-input"
                    error={passwordErrorMessage !== ""}
                    helperText={passwordErrorMessage}
                    fullWidth
                    margin={tabValue === 1 ? "dense" : "densex"}
                    id="password"
                    label={t("password")}
                    type={showPassword ? "text" : "password"}
                    onChange={(event) => {
                      checkEmpty(
                        event.target.value,
                        "password",
                        t("password"),
                        setPasswordErrorMessage
                      );

                      if (tabValue === 1) {
                        checkRegex(
                          event.target.value,
                          "password",
                          passwordRegex,
                          t("password_not_valid"),
                          setPasswordErrorMessage
                        );
                        comparePassword(event.target.value, passwordAgain);
                      }
                      setPassword(event.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            className="eye-icon"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? (
                              <i className="ri-eye-off-line" />
                            ) : (
                              <i className="ri-eye-line" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    className="text-input"
                    error={passwordAgainErrorMessage !== ""}
                    helperText={passwordAgainErrorMessage}
                    fullWidth
                    margin="dense"
                    id="passwordAgain"
                    label={t("password_again")}
                    type={showPasswordAgain ? "text" : "password"}
                    onChange={(event) => {
                      checkEmpty(
                        event.target.value,
                        "passwordAgain",
                        t("password_again"),
                        setPasswordAgainErrorMessage
                      );

                      checkRegex(
                        event.target.value,
                        "passwordAgain",
                        passwordRegex,
                        t("password_not_valid"),
                        setPasswordAgainErrorMessage
                      );

                      comparePassword(password, event.target.value);
                      setPasswordAgain(event.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            className="eye-icon"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? (
                              <i className="ri-eye-off-line" />
                            ) : (
                              <i className="ri-eye-line" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <Box
                  sx={{
                    pt: 2,
                    textAlign: "left",
                    width: "100%",
                    color: "#151518",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 600,
                      color: "#151518",
                    }}
                  >
                    {t("address_info")}
                  </Typography>
                </Box>
                <div className="form-row">
                  <TextField
                    error={cityErrorMessage !== ""}
                    helperText={cityErrorMessage}
                    fullWidth
                    margin="dense"
                    id="city"
                    label={t("city")}
                    onChange={(event) => {
                      checkEmpty(
                        event.target.value,
                        "city",
                        t("city"),
                        setCityErrorMessage
                      );

                      setCity(event.target.value);
                    }}
                  />
                  <TextField
                    error={zipCodeErrorMessage !== ""}
                    helperText={zipCodeErrorMessage}
                    fullWidth
                    margin="dense"
                    id="zipCode"
                    label={t("zipCode")}
                    onChange={(event) => {
                      checkEmpty(
                        event.target.value,
                        "zipCode",
                        t("zipCode"),
                        setZipCodeErrorMessage
                      );

                      checkRegex(
                        event.target.value,
                        "zipCode",
                        zipCodeRegex,
                        t("zipCode_not_valid"),
                        setZipCodeErrorMessage
                      );
                      setZipCode(event.target.value);
                    }}
                  />
                  <FormControl
                    error={countryIdErrorMessage !== ""}
                    fullWidth
                    margin="dense"
                  >
                    <InputLabel id="country-label">{t("country")}</InputLabel>
                    <Select
                      labelId="country-label"
                      id="country"
                      onChange={(event) => {
                        checkEmpty(
                          event.target.value,
                          "countryId",
                          t("country"),
                          setCountryIdErrorMessage
                        );
                        setCountryId(event.target.value);
                      }}
                      label={t("country")}
                    >
                      {countries !== null &&
                        countries.length > 0 &&
                        countries.map((country) => (
                          <MenuItem value={country.id}>{country.name}</MenuItem>
                        ))}
                    </Select>
                    {countryIdErrorMessage !== "" && (
                      <FormHelperText>{countryIdErrorMessage}</FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="form-row">
                  <TextField
                    error={addressErrorMessage !== ""}
                    helperText={addressErrorMessage}
                    minRows={2}
                    fullWidth
                    margin="dense"
                    id="address"
                    label={t("address")}
                    multiline
                    onChange={(event) => {
                      checkEmpty(
                        event.target.value,
                        "address",
                        t("address"),
                        setAddressErrorMessage
                      );

                      checkRegex(
                        event.target.value,
                        "address",
                        addressRegex,
                        t("address_not_valid"),
                        setAddressErrorMessage
                      );
                      setAddress(event.target.value);
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </DialogContent>
        <div className="sub-actions">
          <div className="check">
            <Checkbox
              sx={{
                color: "#E9EBF1",
                "&.Mui-checked": {
                  color: "#E9EBF1",
                },
              }}
            />
            {tabValue === 1 ? (
              <span>
                <a target="_blank" href="/page/agb">
                  {t("i_accept_membership")}
                </a>
              </span>
            ) : (
              <span>{t("remember_me")}</span>
            )}
          </div>
          {false && <p>Forgot Password?</p>}
        </div>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button className="btn" type="submit">
            {tabValue === 1 ? t("register") : t("login")}
          </Button>
        </DialogActions>
        <p className="register-bottom">
          {tabValue === 0 ? (
            <>
              {t("not_member_yet")}?
              <div className="changer" onClick={() => handleTabChange(null, 1)}>
                {t("register")}
              </div>
            </>
          ) : (
            <>
              {t("are_you_member")}?
              <div className="changer" onClick={() => handleTabChange(null, 0)}>
                {" "}
                {t("login")}
              </div>
            </>
          )}
        </p>
      </form>
    </Dialog>
  );
}

export default Login;
