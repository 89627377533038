import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import { useTranslation } from "react-i18next";
import { config } from "../../services/config";
import BasketContext from "../../store/basket-context";
import LoginContext from "../../store/login-context";
import useFormatHelper from "../../useFormatHelper";
import ProductItemDialog from "../ProductItemDialog/ProductItemDialog";
import "./ProductItem.css";
//import { useTranslation } from "react-i18next";
import ProductBottom from "../ProductBottom/ProductBottom";
import Favorite from "../Favorite/Favorite";

//PENDING(0),
//NEW(1),
//PROCESSING(2),
//READY_FOR_DELIVERY(3),
//CANCELLED(4),
//COMPLETED(5),
//MOVED(6),
//ARCHIVED(7),
//REMOVE_ARCHIVED(8);

function ProductItem(props) {
  const { t } = useTranslation();
  const { basketContext } = useContext(BasketContext);
  const { loginContext } = useContext(LoginContext);
  const { formatMoney } = useFormatHelper();
  const [dialogOpen, setDialogOpen] = useState(false);
  let product = props.product;
  const [favorite, setFavorite] = useState(product.favorite);

  //const { t } = useTranslation();

  let imageUrl = "/no-photo.png";
  if (product.image != null) {
    imageUrl = config.baseOrigin + product.image;
  }

  const [count, setCount] = useStateIfMounted(0);
  const basketItemDiscount = basketContext.items.filter(
    (f) => f.productId === product.id && f.price !== f.defaultPrice
  )[0];

  let unitPrice = basketItemDiscount
    ? basketItemDiscount.salesUnitItemUnitPrice
    : product.salesUnit.unitPrice;
  if (isNaN(unitPrice)) {
    unitPrice = "-,";
  } else {
    unitPrice = formatMoney(unitPrice);
  }
  let price = basketItemDiscount
    ? basketItemDiscount.salesUnitItemUnitPrice * product.salesUnit.piece
    : product.salesUnit.price;

  if (isNaN(price)) {
    price = "-,";
  } else {
    price = formatMoney(price);
  }

  let productSalesUnitTitle = "";

  if (product.salesUnit.piece !== 1) {
    productSalesUnitTitle = product.name;
  }

  const categoryName =
    props.categoryName === "New Products" ||
    props.categoryName === "Neue Produkte" ||
    props.categoryName === "Yeni Ürünler"
      ? "green"
      : "";

  const shortTitle = (productName, limit) => {
    if (productName.length > limit) {
      let words = productName.split(" ");
      let newProductName = "";
      let nameLength = 0;

      // Kelimeleri ekleyerek toplam uzunluğu kontrol et
      for (let i = 0; i < words.length; i++) {
        if (nameLength + words[i].length <= limit) {
          newProductName += words[i] + " ";
          nameLength += words[i].length + 1; // 1 eklemek boşluğu temsil eder
        } else {
          break;
        }
      }

      newProductName = newProductName.trim() + "...";

      return newProductName;
    } else {
      return productName;
    }
  };

  useEffect(() => {
    setCount(basketContext.getItem(product.id, product.salesUnit.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketContext, product.id, product.salesUnit.id]);
  return (
    <>
      <Box className="pd">
        <Card
          className={
            product.discount
              ? `product-height discount-border mobile`
              : `product-height mobile`
          }
          variant="standard"
          style={{ position: "relative" }}
        >
          <div className="card-head">
            {loginContext.user && (
              <>
                <div className="card-svg-block right cursor">
                  <Favorite
                    product={product}
                    favorite={favorite}
                    setFavorite={setFavorite}
                  />
                </div>
              </>
            )}
            {product.discount && (
              <div className="card-discount">
                %
                {product.discountRate ??
                  formatMoney(
                    ((product.salesUnit.piece * product.salesUnit.unitPrice -
                      product.salesUnit.defaultPrice) /
                      product.salesUnit.defaultPrice) *
                      100
                  )}
              </div>
            )}
            {product.discount === undefined && categoryName === "green" && (
              <div className="card-new">{t("new")}</div>
            )}
          </div>
          <CardActionArea
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            <CardMedia
              component="img"
              height="148px"
              image={imageUrl}
              sx={{ objectFit: "contain"}}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/no-photo.png";
              }}
            />

            <CardContent sx={{ pb: 0, pt: 1 }}>
              <Typography component="div" className="card-cat-name">
                {product.brandName}
              </Typography>
              <div className="card-header-title">
                {product.salesUnit.piece !== 1 && (
                  <>
                    <Typography component="div" className="card-product-name">
                      {shortTitle(productSalesUnitTitle, 30)}
                    </Typography>
                    <Typography
                      component="div"
                      className="mobile-card-pruduct-name"
                    >
                      {shortTitle(product.name, 20)}
                    </Typography>
                  </>
                )}
                {product.salesUnit.piece === 1 && (
                  <>
                    <Typography component="div" className="card-product-name">
                      {shortTitle(product.name, 30)}
                    </Typography>
                    <Typography
                      component="div"
                      className="mobile-card-pruduct-name"
                    >
                      {shortTitle(product.name, 20)}
                    </Typography>
                  </>
                )}
                <div className="bot-top">
                  <div className="x-unit">{product.unit}</div>
                </div>
              </div>
            </CardContent>
          </CardActionArea>
          <ProductBottom
            green={categoryName}
            readonly={false}
            product={product}
            count={count}
            price={price}
            unitPrice={unitPrice}
          />
        </Card>
      </Box>
      <ProductItemDialog
        productId={product.id}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        setFavorite={setFavorite}
        favorite={favorite}
      />
    </>
  );
}

export default ProductItem;
