import React, { useContext, useEffect } from "react";
import LoginContext from "../../../store/login-context";
import { useStateIfMounted } from "use-state-if-mounted";
import { authenticationService } from "../../../services/control/authenticationService";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import "./Login.css";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { loginContext } = useContext(LoginContext);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [username, setUserName] = useStateIfMounted("");
  const [password, setPassword] = useStateIfMounted("");

  function formSubmit() {
    authenticationService
      .login(username, password, "stockAgent")
      .then((response) => {
        enqueueSnackbar(t("success_login"), {
          variant: "success",
          autoHideDuration: 2000,
        });

        loginContext.setLoginState("user", response);
        navigate("/apps/control");
      })
      .catch((error) => {
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: 2000,
        });
      });
  }

  useEffect(() => {
    if (loginContext.user?.token) {
      navigate("/apps/control");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="login-page">
      <div className="login-fh">
        <div className="login-header">Login</div>
        <div className="login-body">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formSubmit();
            }}
          >
            <div className="form-row">
              <label>{t("username")}</label>
              <input
                type="text"
                onChange={(event) => {
                  setUserName(event.target.value);
                }}
                required
              />
            </div>
            <div className="form-row">
              <label>Password</label>
              <input
                type="password"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                required
              />
            </div>
            <div className="form-row">
              <input type="submit" value={"Login"} className="submit-btn" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
